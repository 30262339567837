<template>
  <div class="block">
    <el-carousel class="part-content" height="330px" type="card">
      <el-carousel-item style="width: 700px;" >
        <img src="@/assets/shutterstock_1401229586.jpg" />
      </el-carousel-item>
      <el-carousel-item style="width: 700px">
        <img src="@/assets/thumb-mobility.jpg" />
      </el-carousel-item>
      <el-carousel-item style="width: 700px">
        <img src="@/assets/16430944918772902.jpg" />
      </el-carousel-item>
    </el-carousel>
  </div>
  <div class="dark-block">
    <div class="part-content features">
      <el-card
        :key="idx"
        :body-style="{ padding: '0px', 'padding-bottom': '12px' }"
      >
        <a href="#/company">
          <img style="width: 350px" src="@/assets/company.jpg" />
        </a>
        <div class="feature-title">会社案内</div>
        <div class="feature-content">
          <a href="#/company">会社案内</a>
        </div>
      </el-card>
      <el-card
        :key="idx"
        :body-style="{ padding: '0px', 'padding-bottom': '12px' }"
      >
        <a href="#/service">
          <img style="width: 350px" src="@/assets/region_bg.png" />
        </a>
        <div class="feature-title">事業案内</div>
        <div class="feature-content">
          <a href="#/service">事業案内</a>
        </div>
      </el-card>
      <el-card
        :key="idx"
        :body-style="{ padding: '0px', 'padding-bottom': '12px' }"
      >
        <a href="#/info">
          <img src="@/assets/af2baf3b62ba9ee43180.jpeg" />
        </a>
        <div class="feature-title">お知らせ</div>
        <div class="feature-content">
          <a href="#/info">お知らせ</a>
        </div>
      </el-card>
    </div>
  </div>
  <div class="block">
    <el-timeline class="part-content">
      <el-timeline-item timestamp="2021-04-26">株式会社ジャンプソフト設立</el-timeline-item>
      <el-timeline-item timestamp="2022-03-31">第一期目決算完了</el-timeline-item>
      <el-timeline-item timestamp="2023-03-31">第二期目決算完了</el-timeline-item>
      <el-timeline-item timestamp="2023-04-13">文京区湯島２－２－１に本店転移しました。</el-timeline-item>
    </el-timeline>
  </div>
  <div class="dark-block">
    <div class="part-content partners">
      <div class="partners-title">パートナーズ</div>
      <div class="partners-list">
        <img v-for="idx in 9" :key="idx" src="@/assets/facebook.png" />
      </div>
    </div>
  </div>
  <div class="block">
    <div class="part-content inquiry">
      <div class="inquiry-title">お問い合わせ</div>
      <el-form
        ref="inquiryForm"
        :model="inquiryForm"
        label-position="left"
        label-width="auto"
      >
        <el-form-item
          prop="customerName"
          :rules="{ required: true, message: 'お名前を入力してください' }"
          label="お名前"
        >
          <el-input v-model="inquiryForm.customerName" />
        </el-form-item>
        <el-form-item
          prop="kana"
          :rules="{ required: true, message: 'ふりがなを入力してください' }"
          label="ふりがな"
        >
          <el-input v-model="inquiryForm.kana" />
        </el-form-item>
        <el-form-item label="御社名">
          <el-input v-model="inquiryForm.companyName" />
        </el-form-item>
        <el-form-item
          prop="contact1"
          :rules="[
            { required: true, message: 'お連絡先１を入力してください' },
            contactRule(inquiryForm.contactType1),
          ]"
          label="お連絡先１"
        >
          <el-input v-model="inquiryForm.contact1">
            <template #prepend>
              <el-select
                style="width: 90px"
                v-model="inquiryForm.contactType1"
              >
                <el-option label="メール" value="email" />
                <el-option label="電話" value="phone" />
              </el-select>
            </template>
          </el-input>
        </el-form-item>
        <el-form-item
          prop="contact2"
          :rules="contactRule(inquiryForm.contactType2)"
          label="お連絡先２"
        >
          <el-input v-model="inquiryForm.contact2">
            <template #prepend>
              <el-select
                style="width: 90px"
                v-model="inquiryForm.contactType2"
              >
                <el-option label="メール" value="email" />
                <el-option label="電話" value="phone" />
              </el-select>
            </template>
          </el-input>
        </el-form-item>
        <el-form-item
          prop="message"
          :rules="{
            required: true,
            message: 'お問い合わせ内容を入力してください',
          }"
          label="お問い合わせ内容"
        >
          <el-input
            class="inquiry-message"
            type="textarea"
            autosize
            resize="none"
            v-model="inquiryForm.message"
          />
        </el-form-item>
        <div style="text-align: center">
          <el-button type="primary" @click="sendInquiry">送信</el-button>
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      inquiryForm: {
        customerName: "",
        kana: "",
        contactType1: "email",
        contact1: "",
        contactType2: "phone",
        contact2: "",
        companyName: "",
        message: "",
      },
    };
  },
  methods: {
    contactRule(type) {
      switch (type) {
        case "email":
          return { type, message: "メールアドレスを正しく入力してください" };
        case "phone":
          return {
            pattern: /^(?:\d{10,11}|\d{3}-\d{3}-\d{4}|\d{2}-\d{4}-\d{4}|\d{3}-\d{4}-\d{4})$/,
            message: "電話番号を正しく入力してください"
          };
      }
    },
    async sendInquiry() {
      try { await this.$refs.inquiryForm.validate(); } catch { return; }
      console.log(this.inquiryForm);
      //
    },
  },
};
</script>

<style lang="sass" scoped>
.block, .dark-block
  overflow-x: hidden
.block
  .part-content-out
    transform: translateX(-48px)
.dark-block
  background-color: #000099
  .part-content-out
    transform: translateX(48px)
.part-content
  max-width: 1200px
  padding: 24px
  margin: auto
  transition: opacity 1s, transform 1s
.part-content-out
  opacity: 0.2
.el-carousel
  img
    max-width: 100%
    max-height: 100%
.el-timeline
  padding: 24px 25%
.features
  display: flex
  justify-content: center
  flex-wrap: wrap
  .el-card
    max-width: 360px
    margin: 12px
    img
      width: 100%
    .feature-title
      font-weight: bold
      margin: 4px 8px
    .feature-content
      margin: 8px
      overflow-wrap: break-word
.partners
  .partners-title
    color: white
    text-align: center
    font-weight: bold
    font-size: 24px
  .partners-list
    display: flex
    justify-content: center
    flex-wrap: wrap
    margin: 24px 0px
    img
      width: 64px
      margin: 24px
.inquiry
  .inquiry-title
    text-align: center
    font-weight: bold
    font-size: 24px
    margin-bottom: 24px
  .el-form
    width: 50%
    min-width: 400px
    margin: auto
    .inquiry-message
      :deep(textarea)
        min-height: 120px!important
    @media (orientation: portrait)
      width: 100%
      min-width: unset

</style>